import { useTranslation } from "react-i18next";

const Partner = ()=>{

    const [t, i18n] = useTranslation("global")
    if(t("header.services") === "الخدمات"){
        document.body.style.textAlign = "right"
    }
    return(
        <div className="container partners">
            <h1>{t("partners")}</h1>
            <div>
                <img src="./logo-medmar.png" alt="logo" width="150px"/>
                <img src="./logo2.png" alt="logo" width="150px"/>
                <img src="./test.jpg" alt="test" width="100px"/>
            </div>
        </div>
    )
}
export default Partner;