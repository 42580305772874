import { useTranslation } from "react-i18next"

const Contact = ()=>{
    const [t, i18n] = useTranslation("global");
    if(t("header.services") === "الخدمات"){
        document.body.style.textAlign = "right"
    }
    return(
        <>
            <div className="contactez-nous">
                <div className="imgs">
                    <img src="./fonddd.jpg" alt="fondd"/>
                    <img src="./plage.jpg" alt="plage"/>
                </div>  
                <div className="container">
                    <h3>{t("contact.1")}</h3><h1>{t("contact.2")}</h1>
                    <button><a href="#test" style={{textDecoration:'none',color:'black'}}>{t("contact.3")}</a></button>
                    <br/><br/><br/>
                </div>
            </div>
            <div className="contact" id="test">
                <div className="container">
                    <h1>{t("contact.7")}</h1>
                    <form className="form-contact">
                        <input placeholder="Nom" name="nom" className="nom" type="text"/>
                        <input placeholder="E-mail" name="email" className="email" type="email"/>
                        <input placeholder="Addresse" name="addresse" className="addresse" type="text"/>
                        <input placeholder="Téléphone" name="telephone" className="telephone" type="text"/>
                        <textarea placeholder="message" className="message"></textarea><br/>
                        <button type="submit">{t("contact.4")}</button>
                    </form>
                </div>
            </div>
            <div className="contact-informations">
                <a href="/"><img src="./logo.jpg" alt="logo" width="220px"/></a>
                <a href="/contact"><button>{t("contact.5")}</button></a>
                <div className="informations">
                    <span><a href="#">France :  +33 0788692794</a> | <a href="#">Maroc : +212 661542825</a></span>
                    <span><a href="mailto:mohammedabakrim@gmail.com">hk.darmodul@gmail.com</a></span>
                    <span style={{fontWeight:'bolder'}}>Bureau N°8 Imm Yousra 2ème Etage Rue Marrakech Hay El Hassani - Inzgane</span>
                </div>
                <div>
                    <a href="#"><img src="./facebook.svg" alt="facebook" width="16px"/></a>
                    <a href="#"><img src="./instagram.svg" alt="instagram" width="20px"/></a>
                </div>
                <span>&copy; {t("contact.6")}</span>
            </div>
        </>
    )
}
export default Contact;