import { useTranslation } from "react-i18next";

const About = ()=>{

    const [t, i18n] = useTranslation("global");
    if(t("header.services") === "الخدمات"){
        document.body.style.textAlign = "right"
    }
    return(
        <>
            <div className="a-propos">
                <div className="container">
                    <div>
                        <h1>{t("about.who.1")}</h1>
                        <p>{t("about.who.2")}</p>
                        <img src="./invest.jpg" width="400px"/>
                    </div>
                    <img src="./p16.jpg" width="750px"/>
                </div>
                <p className="slogant5">{t("about.who.slogant.1")}<span style={{color:'green'}}>{t("about.who.slogant.2")}</span>{t("about.who.slogant.3")}<span style={{color:'red'}}>{t("about.who.slogant.4")}</span></p>
            </div>
            <div className="bureauu container">
                <div className="container-fluid">
                    <div style={{display:'flex'}}>
                        <img src="./agauche.jpg" width="200px"/>
                        <img src="./tradition.jpg" width="200px"/>
                        <img src="./p12.jpg" alt="p11" width="200px"/>
                    </div>
                    <div>
                        <h1>{t("about.office.1")}</h1>
                        <p>{t("about.office.2")}</p>
                    </div>
                </div>
            </div>

            <div className="aventage container">
                <img src="./aventage.jpg" alt="aventage" width="400px"/>
                <div>
                    <h3>{t("about.aventage.1.1")}<span style={{color:'green', opacity:1, fontWeight:'bolder', fontSize:'1.5rem'}}>{t("about.aventage.1.2")} </span><span style={{color:'red', fontWeight:'bolder', fontSize:'1.5rem'}}>{t("about.aventage.1.3")}</span></h3>
                    <div>
                        <p style={{fontWeight:'bolder'}}><img src="./p13.png" alt="v" width="40px"/>{t("about.aventage.2.1")}</p>
                        <p style={{fontWeight:'bolder'}}><img src="./p13.png" alt="v" width="40px"/>{t("about.aventage.2.2")}</p>
                        <p style={{fontWeight:'bolder'}}><img src="./p13.png" alt="v" width="40px"/>{t("about.aventage.2.3")}</p>
                        <p style={{fontWeight:'bolder'}}><img src="./p13.png" alt="v" width="40px"/>{t("about.aventage.2.4")}</p>
                        <p style={{fontWeight:'bolder'}}><img src="./p13.png" alt="v" width="40px"/>{t("about.aventage.2.5")}</p>
                        <p style={{fontWeight:'bolder'}}><img src="./p13.png" alt="v" width="40px"/>{t("about.aventage.2.6")}</p>
                        <p style={{fontWeight:'bolder'}}><img src="./p13.png" alt="v" width="40px"/>{t("about.aventage.2.7")}</p>
                        <p style={{fontWeight:'bolder'}}><img src="./p13.png" alt="v" width="40px"/>{t("about.aventage.2.8")}</p>
                        <p style={{fontWeight:'bolder'}}><img src="./p13.png" alt="v" width="40px"/>{t("about.aventage.2.9")}</p>
                    </div>
                </div>
            </div>
            <div className="engagement">
                <div className="container">
                    <div>
                        <div>
                            <img src="./p13.png" alt="p13" width="50px"/>
                            <p style={{fontWeight:'bold'}}>{t("about.engagement.1")}</p>
                        </div>
                        <div>
                            <img src="./p13.png" alt="p13" width="50px"/>
                            <p style={{fontWeight:'bold'}}>{t("about.engagement.2")}</p>
                        </div>
                        <div>
                            <img src="./p13.png" alt="p13" width="50px"/>
                            <p style={{fontWeight:'bold'}}>{t("about.engagement.3")}</p>
                        </div>
                        <div>
                            <img src="./p13.png" alt="p13" width="50px"/>
                            <p style={{fontWeight:'bold'}}>{t("about.engagement.4")}</p>
                        </div>
                        <div>
                            <img src="./p13.png" alt="p13" width="50px"/>
                            <p style={{fontWeight:'bold'}}>{t("about.engagement.5")}</p>
                        </div>
                        <div>
                            <img src="./p13.png" alt="p13" width="50px"/>
                            <p style={{fontWeight:'bold'}}>{t("about.engagement.6")}</p>
                        </div>
                        <div>
                            <img src="./p13.png" alt="p13" width="50px"/>
                            <p style={{fontWeight:'bold'}}>{t("about.engagement.7")}</p>
                        </div>
                        <img  style={{marginBottom:'3rem'}} src="./engage.png" alt="engagement" width="400px"/><br/><br/>
                    </div>
                    <img src="./acote.png" alt="engagement" width="400px"/>
                </div>
            </div>
        </>
    )
}
export default About;