import { useState } from "react";
import { useTranslation } from "react-i18next";

const Professional = ()=>{
    const [t, i18n] = useTranslation("global");
    return(
        <div className="produits">
            <div className="single-produit container">
                <video src="./trois.mp4" type="video/mp4" autoplay controls></video>
                <img src="./construction.jpg" alt="villa" width="300px"/>
                <img src="./photo8.jpeg" alt="12" width="300px"/>
            </div>
            <p className="slogant2">{t("professional-products.1")}</p>
            <div className="single-produit container">
                <img src="./produit2.jpg" alt="produit2" width="300px"/>
                <img src="./produit3.jpg" alt="produit2" width="300px"/>
                <img src="./produit4.jpg" alt="produit2" width="300px"/>
            </div>
            <div className="single-produit container">
                <img src="./p9.jpg" alt="produit2" width="300px"/>
                <img src="./p18.jpg" alt="produit2" width="300px"/>
                <img src="./p10.jpg" alt="produit2" width="300px"/>
            </div>
            <p className="slogant3">{t("professional-products.slogant.1")}<span style={{color:'red'}}>{t("professional-products.slogant.2")}</span>{t("professional-products.slogant.3")}<span style={{color:'green'}}>{t("professional-products.slogant.4")}</span></p>
        </div>
    )
}
export default Professional;