import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import global_en from "./Translation/en/global.json";
import global_fr from "./Translation/fr/global.json";
import global_ar from "./Translation/ar/global.json";
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';


i18next.init({
    interpolation:{escapeValue:false},
    lng:"fr",
    resources:{
        fr:{
            global:global_fr
        },
        en:{
            global:global_en
        },
        ar:{
            global:global_ar
        }
    }
})




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <I18nextProvider i18n={i18next}>
        <App/>
    </I18nextProvider>
);
