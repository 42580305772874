import { Link } from "react-router-dom";
import Nav from "./Nav";
import { useTranslation } from "react-i18next";

const Home = ()=>{
    const [t, i18n] = useTranslation("global");
    if(t("header.services") === "الخدمات"){
        document.body.style.textAlign = "right"
    }
    return(
        <>
            <div className="about">
                <div className="container">
                    <Link className="link" to="/about">{t("home.us")}</Link>
                    <div className="description">
                        <p><span style={{color:'green'}}>{t("home.description.p1.1")}</span> {t("home.description.p1.2")}</p>
                        <p>{t("home.description.p2.1")} <span style={{color:'red'}}>{t("home.description.p2.2")}</span></p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Home;