import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const Nav = ()=>{
    const navigate = useNavigate();
    const [t, i18n] = useTranslation("global")
    if(t("header.services") === "الخدمات"){
        document.body.style.textAlign = "right"
    }
    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    }
    return(
        <div className="container">
            <div className="header">
                <Link to="/" className="logo">
                    <a href="/"><img src="./logo.jpg" alt="logo"/></a>
                    <p>{t("header.logo.1")} <span style={{color:'red'}}>{t("header.logo.2")}</span></p>
                </Link>
                <ul>
                    <li><Link to="/" className="link">{t("header.home")}</Link></li>
                    <li><Link className="link" to="/services">{t("header.services")}</Link></li>
                    <li className="hello">
                        <select onChange={(e)=>e.target.value==="/"?'none':navigate(e.target.value)}>
                            <option value="/">{t("header.products.1")}</option>
                            <option value="/products-particular"><Link to="/products-particular">{t("header.products.2")}</Link></option>
                            <option value="/products-professional"><Link to="/products-professional">{t("header.products.3")}</Link></option>
                        </select>
                    </li>
                    <li><Link className="link" to="/office">{t("header.office")}</Link></li>
                    <li><Link to="/partners" className="link">{t("header.partners")}</Link></li>
                    <li><Link to="/contact" className="link-contact">{t("header.contact")}</Link></li>
                </ul>
                <select onChange={(e)=>handleChangeLanguage(e.target.value)}>
                    <option value="fr">Fr</option>
                    <option value="en">En</option>
                    <option value="ar">Ar</option>
                </select>
            </div>
            
        </div>
    )
}
export default Nav;