import { useTranslation } from "react-i18next";


const Service = ()=>{
    const [t, i18n] = useTranslation("global");
    if(t("header.services") === "الخدمات"){
        document.body.style.textAlign = "right"
    }
    return(
        <>
            <div className="why container">
                <h1>{t("services.title")}</h1>
                <img className="fleche" src="./fleshe.png" alt="fleshe"/>
                <div className="vies">
                    <div>
                        <img src="p13.png" width="50px"/>
                        <h5>{t("services.props.1")}</h5>
                    </div>
                    <div>
                        <img src="p13.png" width="50px"/>
                        <h5>{t("services.props.2")}</h5>
                    </div>
                    <div>
                        <img src="p13.png" width="50px"/>
                        <h5>{t("services.props.3")}</h5>
                    </div>
                    <div>
                        <img src="p13.png" width="50px"/>
                        <h5>{t("services.props.4")}</h5>
                    </div>
                </div>
            </div>
            <p className="slogant1">{t("services.slogant.1")}<span style={{color:'red'}}>{t("services.slogant.2")}</span>{t("services.slogant.3")}<span style={{color:'green'}}>{t("services.slogant.4")}</span>{t("services.slogant.5")}</p>
            <div className="services container-fluid">
                <div className="single-service">
                    <img src="./shakinghands.jpg" alt="service"/>
                    <div>
                        <h3>{t("services.services.1")}</h3>
                    </div>
                </div>  
                <div className="single-service">
                    <img src="./fin.jpg" alt="service"/>
                    <div>
                        <h3>{t("services.services.2")}</h3>
                    </div>
                </div>       
                <div className="single-service">
                    <img src="./gestion.jpg" alt="service"/>
                    <div>
                        <h3>{t("services.services.3")}</h3>
                    </div>
                </div> 
                <div className="single-service">
                    <img src="./assistant.jpg" alt="service"/>
                    <div>
                        <h3>{t("services.services.4")}</h3>
                    </div>
                </div>   
                <div className="single-service">
                    <img src="./pilotage.jpg" alt="service"/>
                    <div>
                        <h3>{t("services.services.5")}</h3>
                    </div>
                </div>        
            </div>
        </>
    )
}
export default Service;