import { BrowserRouter, Route, Routes } from "react-router-dom";
import Nav from "./Componants/Nav";
import Home from "./Componants/Home";
import Service from "./Componants/Service";
import Partner from "./Componants/Partner";
import Particular from "./Componants/Particular";
import Professional from "./Componants/Professional";
import Office from "./Componants/Office";
import Contact from "./Componants/Contact";
import About from "./Componants/About";
import { useTranslation } from "react-i18next";

function App() {
  return (
    <BrowserRouter>
      <Nav/>
      <Routes>
        <Route path="/" exact element={<Home/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/partners" element={<Partner/>}/>
        <Route path="/services" element={<Service/>}/>
        <Route path="/products-particular" element={<Particular/>}/>
        <Route path="/products-professional" element={<Professional/>}/>
        <Route path="/office" element={<Office/>}/>
        <Route path="/contact" element={<Contact/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
