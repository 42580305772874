import { useTranslation } from "react-i18next";

const Particular = ()=>{
    const [t, i18n] = useTranslation("global");
    if(t("header.services") === "الخدمات"){
        document.body.style.textAlign = "right"
    }
    return(
        <div className="produits">
            <div className="single-produit container">
                <div>
                    <img src="./photo1.jpg" alt="vilamoderne" width="270px"/>
                </div>
                <div>
                    <img src="./photo2.jpg" alt="test" width="270px"/>
                </div>
                <div>
                    <img src="./photo4.webp" alt="deux" width="270px"/>
                </div>
            </div>
            <p className="slogant2">{t("particular-products.1")}</p>
            <div className="single-produit container">
                <div>
                    <img src="./phpt.jpg" alt="deux" width="270px"/>
                </div>
                <div>
                    <img src="./photo6.jpg" alt="deux" width="270px"/>
                </div>
                <div>
                    <img src="./photo7.jpg" alt="deux" width="270px"/>
                </div>
                <div>
                    <img src="./photo5.jpg" alt="deux" width="270px"/>
                </div>
            </div>
            <p className="slogant3">{t("particular-products.slogant.1")}<span style={{color:'red'}}>{t("particular-products.slogant.2")}</span>{t("particular-products.slogant.3")}<span style={{color:'green'}}>{t("particular-products.slogant.4")}</span></p>
        </div>
    )
}
export default Particular;