import { useState } from "react";
import { useTranslation } from "react-i18next";

const Office = ()=>{

    const [t, i18n] = useTranslation("global");
    return(
        <>
            <div className="container bureau">
                <div>
                    <img src="./bureau.jpeg" alt="bureau"/>
                    <img src="./brique.jpg" alt="brique"/>
                    <p>{t("office.office.1")}</p>
                </div>
                <div>
                    <h1>{t("office.office.2")}</h1>
                    <p>{t("office.office.3")}</p>
                    <p>{t("office.office.4")}</p>
                    <h1>{t("office.office.5")}</h1>
                    <p>{t("office.office.6")}</p>
                    <p style={{color:'red'}}>{t("office.office.7")}</p>                        
                </div>
            </div>
            <div className="services">
                <div className="single-service" style={{backgroundColor:'#F7F7F7'}}>
                    <img src="./controlebatiment.jpg" alt="service" width="500px" height="350px"/>
                    <div>
                        <img src="./logo2.png" alt="logo2" width="300px" height="200px"/>
                        <h3>{t("office.service")}</h3>
                    </div>
                </div>
                <p className="slogant4"><span style={{color:'green'}}>{t("office.slogant.1")}</span>{t("office.slogant.2")}<span style={{color:'red',fontSize:'1.2rem'}}>{t("office.slogant.3")}</span></p>
            </div>
        </>
    )
}
export default Office;